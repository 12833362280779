import * as React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Table from '../components/Table';

const PluginsPage = ({ data, location }) => {
  const title = 'Matomo plugins';
  return (
    <Layout location={location} title={title}>
      <h1>
        {`Plugins (${
          data.plugins.nodes.length +
          data.pluginsMatomo.nodes.length +
          data.pluginsUnstable.nodes.length +
          data.pluginsOld.nodes.length
        }`}
        )
      </h1>
      <h2>{`${data.plugins.nodes.length} plugins from the community.`}</h2>
      {data?.plugins?.nodes && Table({ data: data.plugins.nodes, owner: true })}
      <h2>{`${data.pluginsMatomo.nodes.length} plugins from Matomo org.`}</h2>
      {data?.pluginsMatomo?.nodes && Table({ data: data.pluginsMatomo.nodes })}
      <h2>{`${data.pluginsUnstable.nodes.length} plugins without a stable release.`}</h2>
      {data?.pluginsUnstable?.nodes &&
        Table({ data: data.pluginsUnstable.nodes, owner: true })}
      <h2>
        {`${data.pluginsOld.nodes.length} plugins (not updated since 2022)`}
      </h2>
      {data?.pluginsOld?.nodes &&
        Table({
          data: data.pluginsOld.nodes,
          owner: true,
          // toplistDownloads: true,
        })}
    </Layout>
  );
};
export const query = graphql`
  {
    plugins: allMatomoPlugin(
      sort: { order: DESC, fields: lastUpdated }
      filter: {
        stable: { eq: true }
        isPaid: { eq: false }
        lastUpdated: { gte: "2023-01-01" }
        owner: { ne: "matomo-org" }
      }
    ) {
      nodes {
        id
        idPath
        displayName
        latestVersion
        lastUpdated
        isPaid
        isCustomPlugin
        createdDateTime
        description
        displayName
        numDownloads
        topListNumDownloads
        repositoryUrl
        owner
      }
    }
    pluginsMatomo: allMatomoPlugin(
      sort: { order: DESC, fields: lastUpdated }
      filter: {
        stable: { eq: true }
        isPaid: { eq: false }
        lastUpdated: { gte: "2023-01-01" }
        owner: { eq: "matomo-org" }
      }
    ) {
      nodes {
        id
        idPath
        displayName
        latestVersion
        lastUpdated
        isPaid
        isCustomPlugin
        createdDateTime
        description
        displayName
        numDownloads
        topListNumDownloads
        repositoryUrl
        owner
      }
    }
    pluginsUnstable: allMatomoPlugin(
      sort: { order: DESC, fields: lastUpdated }
      filter: {
        stable: { eq: false }
        isPaid: { eq: false }
        lastUpdated: { gte: "2023-01-01" }
        owner: { ne: "matomo-org" }
      }
    ) {
      nodes {
        id
        idPath
        displayName
        latestVersion
        lastUpdated
        isPaid
        isCustomPlugin
        createdDateTime
        description
        displayName
        numDownloads
        topListNumDownloads
        repositoryUrl
        owner
      }
    }
    pluginsOld: allMatomoPlugin(
      sort: { order: DESC, fields: lastUpdated }
      filter: {
        stable: { eq: true }
        isPaid: { eq: false }
        lastUpdated: { lt: "2023-01-01" }
      }
    ) {
      nodes {
        id
        idPath
        displayName
        latestVersion
        lastUpdated
        isPaid
        isCustomPlugin
        createdDateTime
        description
        displayName
        numDownloads
        topListNumDownloads
        repositoryUrl
        owner
      }
    }
  }
`;

export default PluginsPage;
